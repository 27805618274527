<template>
  <div class="sub-cont">
    <div class="mypage-wrap" style="padding-bottom: 0">
      <h2 class="h2-mypage">어학</h2>
      <button class="btn __add" @click="addForm">
        <i class="el-icon-plus"></i> 추가
      </button>
      <div class="empty_form" v-if="isLangEmpty">어학사항을 추가하세요.</div>
      <div
        v-for="(language, ix) in languages"
        :key="`la_${ix}`"
        class="input-form-wrap"
      >
        <div class="input-form">
          <div class="t-head">외국어명</div>
          <div class="t-doc">
            <select
              v-model="language.languageCode"
              name=""
              id=""
              class="select"
              :ref="`languageCode_${ix}`"
            >
              <option
                v-for="(languageCode, idx) in languageCodeList"
                :key="`la_${idx}`"
                :value="languageCode.code"
                :selected="languageCode.code == language.languageCode"
              >
                {{ languageCode.codeValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-form">
          <div class="t-head">회화능력</div>
          <div class="t-doc end">
            <select
              v-model="language.fluencyLevelCode"
              name=""
              id=""
              class="select"
              :ref="`fluencyLevelCode_${ix}`"
            >
              <option
                v-for="(levelCode, idx) in levelCodeList"
                :key="`la_${idx}`"
                :value="levelCode.code"
                :selected="levelCode.code == language.fluencyLevelCode"
              >
                {{ levelCode.codeValue }}
              </option>
            </select>
          </div>
        </div>
        <div class="btn_box">
          <button class="btn __delete" @click="deleteCert(ix)">삭제</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLanguagesLevelList,
  saveLanguagesLevelList,
  getLanguageCodeList,
  getCommunicationLevelCodeList,
} from 'Api/modules'
import resumeMixin from 'Mixins/resume'

export default {
  mixins: [resumeMixin],
  data() {
    return {
      year: [],
      month: [],
      day: [],
      languageCodeList: [],
      levelCodeList: [],
      languages: [
        {
          languageCode: '',
          fluencyLevelCode: '',
        },
      ],
    }
  },
  computed: {
    isLangEmpty() {
      return this.languages.length == 0
    },
  },
  methods: {
    async save() {
      //입력 오류
      if (!this.checkValues(this.languages)) return false

      try {
        await saveLanguagesLevelList({ languages: this.languages })
        this.$toasted.show('어학정보가 등록되었습니다.')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    deleteCert(idx) {
      this.deleteItemOrClearForm(this.languages, idx)
    },
    addForm() {
      this.languages.push({
        languageCode: '',
        fluencyLevelCode: '',
      })
    },
    async getStartEnd(cond, start, end) {
      for (let i = start; i <= end; i++) {
        if (cond === 'month') this.month.push(i)
        else if (cond === 'year') this.year.push(i)
        else if (cond === 'day') this.day.push(i)
      }
    },
  },
  async created() {
    // 년, 월 세팅
    const startMonth = 1
    const endMonth = 12
    await this.getStartEnd('month', startMonth, endMonth)
    const startYear = 1970
    const endYear = 2040
    await this.getStartEnd('year', startYear, endYear)
    const startDay = 1
    const endDay = 31
    await this.getStartEnd('day', startDay, endDay)

    // 외국어명 코드 목록 조회
    this.languageCodeList = await getLanguageCodeList()

    // 회화능력 코드 목록 조회
    this.levelCodeList = await getCommunicationLevelCodeList()

    // 경력목록 조회
    try {
      const res = await getLanguagesLevelList({ userId: this.account.userId })
      if (res && res.length > 0) {
        this.languages = res
      }
      console.error(res)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
}
</script>

<style>
.empty_form {
  padding-top: 90px;
  height: 160px;
  border: 1px;
  border-style: none none solid none;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
</style>
